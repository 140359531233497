import { Country } from './country';

export class Config {


    public static API_ENDPOINT = 'https://fixit-apis.stageprojects.xyz/api/';

    // public static API_ENDPOINT = "http://localhost:3000/api/";

    public static GOOGLE_MAP_API_KEY = 'AIzaSyAkw0g8fZ840GP9ExZtxx8R-9VLYWegOTU';

    public static API_TIMEOUT = 3000; // 1 second Timeout  

    public static LOGIN_ERROR = 'Either Mobile number/password invalid. Please try again.';

    public static LOGOUT_SUCCESS = 'Successfully Logout.';


    public static SITE_NAME = 'Fixit Now App';


    public static CompleteOTPError = "Please enter complete OTP";

    public static SITE_URL = "https://lifespan.firebaseapp.com";

    public static FaceBookProviderKey = "1423910501193646";

    public static GoogleProviderKey = "969634869334-n92elappphqhjkrajpihakbs34228962.apps.googleusercontent.com";

    public static GENERIC_ERROR = 'There is some error. Please try again.';

    public static Stripe_key = "pk_test_QvUWFYGXzQaPFZFcZ1O3W5vl"

    public static INAPP_PURCHASE = {
        RECIEPT_VALIDATOR_API: "subscription/inapp"
    }
    public static COUNTRIES_LIST = Country.ALL_COUNTRIES;


    public static ROUTE_ANIMATION: any = {
        animation: 'ios-transition',
        duration: 1000
    }

    public static BUCKETPATH = {
        SERVICEBANNERIMAGE: "SERVICEBANNERIMAGE",
        VENDORDOC: "VENDOR",
        PROFILEIMAGE: "PROFILE"
    }

    public static ROLE = {
        SUPERADMIN: "5d1b681f09cc8212e0a6ae34",
        ADMIN: "5d1b67c209cc8212e0a6ae31",
        SUBADMIN: "5d1b67f609cc8212e0a6ae32",
        FRONTEND: "5d1b680809cc8212e0a6ae33",
        VENDOR: "5d1c7186f47e2224a236e56e"
    }

    public static MESSAGE = {
        SMS: {
            SENT_SUCCCESSMSG: "Message Has Sent Successfully",
            SENT_SUCCCESS: "OTP has been sent to your registered phone no.",
            SENT_ERROR: "OTP can not be send to your registered phone no. Please try again.",
            RESENT_SUCCCESS: "OTP has been resent to your registered phone no.",
            VERIFY_SUCCESS: "Phone number has been verified successfully.",
            VERIFY_ERROR: "Wrong OTP entered. Please try again",
            VERIFY_ALREADY: "This phone number already verified. Please try again.",
            LIMIT_REACHED: "OTP has been expired. Please try with resend the OTP."
        },
        USER: {
            ADD_TITLE: "Registerd Successfully",

            ADD: "Congratulations you have created an account. A OTP has been sent to activate the Fixit Now App.",

            EDIT: "User Info has been updated",
            DELETE: "User has been deleted",
            DELETE_CONFIRM: "Are you sure you want to delete this user?",
            SIGNUP_ERR: "Email address already exist. Please try again.",
            FORGET_PASSWORD: "Reset Password link has been sent at your email address.",
            RESET_PASSWORD: "Congratulations! Your password has been reset successfully.",
            CHANGE_PASSWORD: "Congratulations! Your password has been changed successfully.",
        },
        LOGIN: {
            VERFY_ACCESS_CODE_ERR: "Access Code is invalid. Please try again."
        }
    }


    public static SITE_TITLE_PREFIX = Config.SITE_NAME + ' - ';



}
