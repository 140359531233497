import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Config } from '../../core/config';
import { LoaderService } from '../../services/loader/loader.service';
import { ToastComponent } from '../../shared/toast/toast.component';
@Injectable({
  providedIn: 'root'
})
export class AreaService {
  apiUrl : string = Config.API_ENDPOINT;
	loginData: any;
	headers : any;
	accessToken : any;
	userId : any;
  constructor(
    public http: HttpClient,
  	public loadingService : LoaderService,
  	public snackbarCmp : ToastComponent
  ) {
    this.headers = new Headers();

    this.headers.append('Access-Control-Allow-Origin' , '*');
    this.headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
    this.headers.append('Accept','application/json');
    this.headers.append('content-type','application/json');
    this.getLoginUserData();
   }

   getLoginUserData(){
    let loginUserData = JSON.parse(localStorage.getItem("loginUser"));
    if(loginUserData){
      this.accessToken = loginUserData['id'];
      this.userId = loginUserData['userId'];
    }
    
  }

  getAreasDetails(AreasId){

    this.getLoginUserData();

    this.loadingService.showLoading();
  
    let filterJSON = {	
      "order": "createdAt DESC"
    };

  let filter = encodeURI(JSON.stringify(filterJSON));

  
    let AreasURL = this.apiUrl + 'area-managers/' +AreasId
                  + '?access_token=' + this.accessToken
                   + '&filter=' + filter;


    return new Promise((resolve, reject) => {
      this.http.get(AreasURL).subscribe(res => {
      
        this.loadingService.hideLoading();
            resolve(res);
      },(err)=>{
        this.loadingService.hideLoading();
            this.snackbarCmp.presentToast(Config.GENERIC_ERROR);
            reject(err);
      });
    });
  }

  getAllAreas(place){
    this.getLoginUserData();

  this.loadingService.showLoading();
  
  let filterJSON = {	
      "where":{
        "city":{"like":place.trim()}
      },
      "order": "createdAt DESC"
    };
  

  let filter = encodeURI(JSON.stringify(filterJSON));

  let allAreasURL = this.apiUrl + 'area-managers/' 
                + '?access_token=' + this.accessToken
                + '&filter=' + filter;

  return new Promise((resolve, reject) => {
    this.http.get(allAreasURL).subscribe((res:any) => {
      
        this.loadingService.hideLoading();
        resolve(res);
      
    },(err)=>{
      this.loadingService.hideLoading();
          this.snackbarCmp.presentToast(Config.GENERIC_ERROR);
          reject(err);
    });
  });
}

getEveryAreas(){
  this.getLoginUserData();

this.loadingService.showLoading();

let filterJSON = {	
    "where":{
      "status":true
    }  ,
    "order": "createdAt DESC",
    
  };


let filter = encodeURI(JSON.stringify(filterJSON));

let allAreasURL = this.apiUrl + 'area-managers/' 
              + '?access_token=' + this.accessToken
              + '&filter=' + filter;

return new Promise((resolve, reject) => {
  this.http.get(allAreasURL).subscribe((res:any) => {
    
      this.loadingService.hideLoading();
      resolve(res);
    
  },(err)=>{
    this.loadingService.hideLoading();
        this.snackbarCmp.presentToast(Config.GENERIC_ERROR);
        reject(err);
  });
});
}
showHome:boolean=false;

}
