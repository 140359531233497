import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot,CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { NavController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AuthloginService implements CanActivate {
  path: ActivatedRouteSnapshot[];
  route: ActivatedRouteSnapshot;
  constructor(
    private navCtrl:NavController
  ) { }
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) : Observable<boolean> | Promise<boolean> | boolean {
   
    if (localStorage.getItem('userProfile') && Object.keys(JSON.parse(localStorage.getItem('userProfile'))).length > 0 ) {
        this.navCtrl.navigateRoot(['/app']);   
        return false;
          }else{
      return true;
    }
     }
}
