import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Config } from '../../core/config';
import { LoaderService } from '../../services/loader/loader.service';
import { ToastComponent } from '../../shared/toast/toast.component';

@Injectable({
  providedIn: 'root'
})
export class UserAddressService {
  apiUrl : string = Config.API_ENDPOINT;
	loginData: any;
	headers : any;
	accessToken : any;
	userId : any;
  constructor(
    public http: HttpClient,
  	public loadingService : LoaderService,
  	public snackbarCmp : ToastComponent
  ) {
    this.headers = new Headers();

    this.headers.append('Access-Control-Allow-Origin' , '*');
    this.headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
    this.headers.append('Accept','application/json');
    this.headers.append('content-type','application/json');
    this.getLoginUserData();
   }

   getLoginUserData(){
    let loginUserData = JSON.parse(localStorage.getItem("loginUser"));
    if(loginUserData){
      this.accessToken = loginUserData['id'];
      this.userId = loginUserData['userId'];
    }
    
  }

  getUserAddressDetails(UserAddressId){

    this.getLoginUserData();

    this.loadingService.showLoading();
  
    let filterJSON = {	
      "include":['user','User','services','plan'],	
      "order": "createdAt DESC"
    };

  let filter = encodeURI(JSON.stringify(filterJSON));

  
    let UserAddressURL = this.apiUrl + 'user-addresses/' +UserAddressId
                  + '?access_token=' + this.accessToken
                   + '&filter=' + filter;


    return new Promise((resolve, reject) => {
      this.http.get(UserAddressURL).subscribe(res => {
      
        this.loadingService.hideLoading();
            resolve(res);
      },(err)=>{
        this.loadingService.hideLoading();
            this.snackbarCmp.presentToast(Config.GENERIC_ERROR);
            reject(err);
      });
    });
  }

  getAllUserAddresss(userId){
    this.getLoginUserData();

  this.loadingService.showLoading();
  
  let filterJSON = {	
    "where":{
      "userId":userId
    },
      "order": "createdAt DESC"
    };
  

  let filter = encodeURI(JSON.stringify(filterJSON));

  let allUserAddressURL = this.apiUrl + 'user-addresses/' 
                + '?access_token=' + this.accessToken
                + '&filter=' + filter;

  return new Promise((resolve, reject) => {
    this.http.get(allUserAddressURL).subscribe((res:any) => {
      
        this.loadingService.hideLoading();
        resolve(res);
      
    },(err)=>{
      this.loadingService.hideLoading();
          this.snackbarCmp.presentToast(Config.GENERIC_ERROR);
          reject(err);
    });
  });
}

addUserAddress(UserAddressInfo) {
  this.getLoginUserData();
    this.loadingService.showLoading();

    let addUserAddressURL = this.apiUrl + 'user-addresses/' 
            + '?access_token=' + this.accessToken
   

  
  return new Promise((resolve, reject) => {

    this.http.post(addUserAddressURL, UserAddressInfo,{headers:this.headers,withCredentials: true})
      .subscribe(res => {
          this.loadingService.hideLoading();
          // this.dialog.openContentDialog(Config.MESSAGE.USER.ADD_TITLE,Config.MESSAGE.USER.ADD);
          // this.snackbarCmp.presentToast(Config.MESSAGE.USER.ADD);
        resolve(res);
      }, (err) => {
        this.loadingService.hideLoading();
        this.snackbarCmp.presentToast(Config.MESSAGE.USER.SIGNUP_ERR);
        reject(err);
      });
  });
}



editUserAddress(UserAddressInfo,UserAddressId,successMsg = Config.MESSAGE.USER.EDIT) {
        this.getLoginUserData();
    this.loadingService.showLoading();
    


    let editUserAddressURL = this.apiUrl + 'user-addresses/' + UserAddressId + '?access_token=' + this.accessToken;
   

  
  return new Promise((resolve, reject) => {

    this.http.patch(editUserAddressURL, UserAddressInfo,{headers:this.headers,withCredentials: true})
      .subscribe(res => {
          this.loadingService.hideLoading();
          this.snackbarCmp.presentToast(successMsg);
        resolve(res);
      }, (err) => {
        this.loadingService.hideLoading();
        this.snackbarCmp.presentToast(Config.GENERIC_ERROR);
        reject(err);
      });
  });
}

deleteUserAddress(UserAddressId){
        this.getLoginUserData();


  let dialogTitle = "Delete UserAddress";
    let dialogContent = Config.MESSAGE.USER.DELETE_CONFIRM;
        return new Promise((resolve, reject) => {
    
  // this.dialog.openDialog(dialogTitle,dialogContent).then((isOkay)=>{
  //       if(isOkay){
        this.loadingService.showLoading();
        
        let deleteUserAddressURL = this.apiUrl + 'user-addresses/' + UserAddressId + '?access_token=' + this.accessToken;
       

      

        this.http.delete(deleteUserAddressURL,{headers:this.headers,withCredentials: true})
          .subscribe(res => {
              this.loadingService.hideLoading();
              this.snackbarCmp.presentToast(Config.MESSAGE.USER.DELETE);
            resolve(res);
          }, (err) => {
            this.loadingService.hideLoading();
            this.snackbarCmp.presentToast(Config.GENERIC_ERROR);
            reject(err);
         });
        // }
        // });

  })
    
    
}

}
