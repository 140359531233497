import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthService } from '../app/services/authGaurd/auth.service';
import { AuthloginService } from '../app/services/authGaurd/authlogin.service';
import { CheckLocationService } from './services/authGaurd/check-location.service';

const routes: Routes = [
  // {
  //   path: '',
  //   redirectTo: 'app',
  //   pathMatch: 'full'
  // },


  {
    path: '',
    // canActivate: [CheckLocationService],
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'app/home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)

  },
  {
    path: 'app/about',
    loadChildren: () => import('./pages/about/about.module').then( m => m.AboutPageModule)

  },
  {
    path: 'app/contact',
    loadChildren: () => import('./pages/contact/contact.module').then( m => m.ContactPageModule)
   
  },
  {
    path: 'app',
    // canActivate: [CheckLocationService],
    loadChildren: () => import('./pages/tabs/tabs.module').then( m => m.TabsPageModule)
  },
  {
    path: 'folder/:id',
    loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)
  },
  {
    path: 'about',
    loadChildren: () => import('./pages/about/about.module').then( m => m.AboutPageModule)
  },
  {
    path: 'address_multiple',
    canActivate: [AuthService] ,
    loadChildren: () => import('./pages/address-multiple/address-multiple.module').then( m => m.AddressMultiplePageModule)
  },
  {
    path: 'choose-functionality',
    loadChildren: () => import('./pages/choose-functionality/choose-functionality.module').then( m => m.ChooseFunctionalityPageModule)
  },
  {
    path: 'choose-location',
    loadChildren: () => import('./pages/choose-location/choose-location.module').then( m => m.ChooseLocationPageModule)
  },
  {
    path: 'contact',
    loadChildren: () => import('./pages/contact/contact.module').then( m => m.ContactPageModule)
  },
  {
    path: 'Contact',
    loadChildren: () => import('./pages/contact/contact.module').then( m => m.ContactPageModule)
  },
  {
    path: 'contact-us',
    loadChildren: () => import('./pages/contact-us/contact-us.module').then( m => m.ContactUsPageModule)
  },
  {
    path: 'content-page',
    loadChildren: () => import('./pages/content-page/content-page.module').then( m => m.ContentPagePageModule)
  },
  {
    path: 'coupons',
    loadChildren: () => import('./pages/coupons/coupons.module').then( m => m.CouponsPageModule)
  },
  {
    path: 'create-account',
    canActivate: [AuthloginService],
    loadChildren: () => import('./pages/create-account/create-account.module').then( m => m.CreateAccountPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'Home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'login',
    canActivate: [AuthloginService] ,
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'otp',
    canActivate: [AuthloginService] ,
    loadChildren: () => import('./pages/otp/otp.module').then( m => m.OtpPageModule)
  },
  {
    path: 'select-city',
    canActivate: [AuthloginService],
    loadChildren: () => import('./pages/select-city/select-city.module').then( m => m.SelectCityPageModule)
  },
  {
    path: 'select-datetime',
    loadChildren: () => import('./pages/select-datetime/select-datetime.module').then( m => m.SelectDatetimePageModule)
  },
  {
    path: 'select-location',
    canActivate: [AuthService] ,
    loadChildren: () => import('./pages/select-location/select-location.module').then( m => m.SelectLocationPageModule)
  },
  {
    path: 'select-locationsearch',
    canActivate: [AuthService] ,
    loadChildren: () => import('./pages/select-locationsearch/select-locationsearch.module').then( m => m.SelectLocationsearchPageModule)
  },
  {
    path: 'service-detail',
    loadChildren: () => import('./pages/service-detail/service-detail.module').then( m => m.ServiceDetailPageModule)
  },
  {
    path: 'subservice/:id',
    loadChildren: () => import('./pages/subservice/subservice.module').then( m => m.SubservicePageModule)
  },
  {
    path: 'success-page',
    canActivate: [AuthService] ,
    loadChildren: () => import('./pages/success-page/success-page.module').then( m => m.SuccessPagePageModule)
  },
  {
    path: 'view-job/:id',
    loadChildren: () => import('./pages/view-job/view-job.module').then( m => m.ViewJobPageModule)
  },
  {
    path: 'View-job/:id',
    loadChildren: () => import('./pages/view-job/view-job.module').then( m => m.ViewJobPageModule)
  },
  {
    path: 'wallet',
    canActivate: [AuthService] ,
    loadChildren: () => import('./pages/wallet/wallet.module').then( m => m.WalletPageModule)
  },
  {
    path: 'cart-detail',
    canActivate: [AuthService] ,
    loadChildren: () => import('./pages/cart-detail/cart-detail.module').then( m => m.CartDetailPageModule)
  },
  {
    path: 'Cart-detail',
    canActivate: [AuthService] ,
    loadChildren: () => import('./pages/cart-detail/cart-detail.module').then( m => m.CartDetailPageModule)
  },
  {
    path: 'privacy',

    loadChildren: () => import('./pages/privacy/privacy.module').then( m => m.PrivacyPageModule)
  },
  {
    path: 'term',
   
    loadChildren: () => import('./pages/term/term.module').then( m => m.TermPageModule)
  },  {
    path: 'search-pagedesign',
    loadChildren: () => import('./pages/search-pagedesign/search-pagedesign.module').then( m => m.SearchPagedesignPageModule)
  },


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, initialNavigation: 'enabled' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
