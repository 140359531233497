import { Injectable } from "@angular/core";

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Config } from "../../core/config";
import { LoaderService } from "../../services/loader/loader.service";
import { ToastComponent } from "../../shared/toast/toast.component";
// import { FCM } from "@ionic-native/fcm/ngx";
import { FCM } from 'cordova-plugin-fcm-with-dependecy-updated/ionic/ngx';
import { NavController, Platform } from "@ionic/angular";
@Injectable({
  providedIn: "root"
})
export class UserService {
  apiUrl: string = Config.API_ENDPOINT;
  loginData: any;
  headers: any;
  accessToken: any;
  userId: any;
  fcmtoken: any = "";
  addUserFormModel: any;

  constructor(
    public http: HttpClient,
    public loadingService: LoaderService,
    public toast: ToastComponent,
    private platform: Platform,
    private fcm: FCM
  ) {
    this.headers = new Headers();

    this.headers.append("Access-Control-Allow-Origin", "*");
    this.headers.append(
      "Access-Control-Allow-Methods",
      "POST, GET, OPTIONS, PUT"
    );
    this.headers.append("Accept", "application/json");
    this.headers.append("content-type", "application/json");
    this.getLoginUserData();
  }

  getLoginUserData() {
    let loginUserData = JSON.parse(localStorage.getItem("loginUser"));
    if (loginUserData) {
      this.accessToken = loginUserData["id"];
      this.userId = loginUserData["userId"];
    }
  }

  verifyOTP(userMobileNo, otp) {
    this.getLoginUserData();

    this.loadingService.showLoading();

    let filterJSON = {};

    let filter = encodeURI(JSON.stringify(filterJSON));
    let sendOtpUrl =
      this.apiUrl +
      "users/verify-user?mobileNo=" +
      userMobileNo +
      "&otp=" +
      otp;

    return new Promise((resolve, reject) => {
      this.http.get(sendOtpUrl).subscribe(
        (res: any) => {
          this.loadingService.hideLoading();
          let verifyMsg = "";
          switch (res.message) {
            case "OTP verified success":
              verifyMsg = Config.MESSAGE.SMS.VERIFY_SUCCESS;
              break;
            case "OTP not match":
            case "OTP not match":
              verifyMsg = Config.MESSAGE.SMS.VERIFY_ERROR;
              break;
            case "max_limit_reached_for_this_otp_verification":
              verifyMsg = Config.MESSAGE.SMS.LIMIT_REACHED;
              break;
            case "Mobile no. already verified":
              verifyMsg = Config.MESSAGE.SMS.VERIFY_ALREADY;
              break;
          }
          this.toast.presentToast(verifyMsg);
          resolve(res);
        },
        err => {
          this.loadingService.hideLoading();
          this.toast.presentToast(Config.MESSAGE.SMS.SENT_ERROR);
          reject(err);
        }
      );
    });
  }

  sendOTP(userMobileNo,HASH) {
    this.getLoginUserData();

    this.loadingService.showLoading();

    let filterJSON = {};

    let filter = encodeURI(JSON.stringify(filterJSON));
    let sendOtpUrl = this.apiUrl + "users/send-otp?mobileNo=" + userMobileNo;
    if(HASH){
      sendOtpUrl +='&HASH='+ encodeURIComponent(HASH) 
    }
    return new Promise((resolve, reject) => {
      this.http.get(sendOtpUrl).subscribe(
        res => {
          this.loadingService.hideLoading();
          this.toast.presentToast(Config.MESSAGE.SMS.SENT_SUCCCESS);
          resolve(res);
        },
        err => {
          this.loadingService.hideLoading();
          this.toast.presentToast(Config.MESSAGE.SMS.SENT_ERROR);
          reject(err);
        }
      );
    });
  }
  social(UserInfo) {
    this.loadingService.showLoading();
  let loginUrl = this.apiUrl + '/customers/social-login?username=' + UserInfo;
  // if(this.platform.is('cordova')){
  //   let that =this;
  //   this.fcm.getToken().then((token)=>{
  //     console.log(token);
  //     that.fcmtoken = token;
  //   })
  // }	let that = this;
    return new Promise((resolve, reject) => {
      this.http.get(loginUrl)
        .subscribe((res:any) => {
      let editInfo = {"fcmToken" : this.fcmtoken };
        this.editUser(editInfo,res.userId).then((ress)=>{
          console.log(ress);
          localStorage.setItem("loginUser",JSON.stringify(res));
          // this.loginUser = true;
            // this.loadingService.getuserDetails();
          this.loadingService.hideLoading();
            resolve(res);
        })
        }, (err) => {
          console.log("error");
          this.loadingService.hideLoading();
          // this.toast.presentToastError(AppSettings.LOGIN_ERROR);
          reject(err);
        });
    });
  }
  getUserEmailCheck(email){
  

    this.loadingService.showLoading();
    
    let filterJSON = {
      "where":{"email":email}
    }
    let filter = encodeURI(JSON.stringify(filterJSON));
    let profileURL = this.apiUrl + '/customers'  
                  + '?filter=' + filter;
  
    return new Promise((resolve, reject) => {
      this.http.get(profileURL).subscribe((res:any) => {
   
        this.loadingService.hideLoading();
            resolve(res);
      },(err)=>{
        this.loadingService.hideLoading();
            // this.toast.presentToastError(AppSettings.GENERIC_ERROR);
            reject(err);
      });
    });
  }

  // login(userInfo) {
  //    this.loadingService.showLoading();

  //   let loginUrl = this.apiUrl + 'customers/login';

  //   return new Promise((resolve, reject) => {

  //     this.http.post(loginUrl, userInfo,{headers: this.headers,withCredentials: true})
  //       .subscribe(res => {
  //         localStorage.setItem("loginUser",JSON.stringify(res));
  //            this.loadingService.hideLoading();
  //         resolve(res);
  //       }, (err) => {
  //          this.loadingService.hideLoading();
  //         this.toast.presentToast(Config.LOGIN_ERROR);
  //         reject(err);
  //       });
  //   });
  // }

  login(userInfo) {
    this.loadingService.showLoading();

    let loginUrl = this.apiUrl + "customers/login";
    if (this.platform.is("cordova")) {
      let that = this;
      this.fcm.getToken().then(token => {
        console.log(token);
        that.fcmtoken = token;
      });
    }
    let that = this;

    return new Promise((resolve, reject) => {
      this.http
        .post(loginUrl, userInfo, {
          headers: this.headers,
          withCredentials: true
        })
        .subscribe(
          (res: any) => {
            let editInfo = { fcmToken: this.fcmtoken };
            this.editUser( editInfo,res.userId).then(ress => {
              console.log(ress);
              localStorage.setItem("userProfile", JSON.stringify(ress));
              let userId = res.userId;
              this.loadingService.hideLoading();
              localStorage.setItem("loginUser", JSON.stringify(res));
              that.accessToken = res["id"];
              that.userId = res["userId"];
              //  if(ress['status'] == false){
              //    this.logout();
              //    this.toast.presentToastError("Your Account has been de-activated. Please contact to Admin.");
              //    reject({"some":"error"});
              //  }else{

              resolve(res);
              //  }
            });
          },
          err => {
            this.loadingService.hideLoading();
            this.toast.presentToast(Config.LOGIN_ERROR);
            reject(err);
          }
        );
    });
  }

  sociallogindata(userdata, sociallogintype) {
    let loginInfo = {
      username: userdata.username,
      password: userdata.username
    };

    this.loadingService.showLoading();
    this.addUserFormModel = {
      emailVerified: false,
      fullName: userdata.first_name + ' ' + userdata.last_name,
      email: userdata.email,
      socialloginid: userdata.id,
      sociallogintype: sociallogintype,
      profileImage: { url: userdata.picture },
      mobileNo: "",
      roleId: Config.ROLE.FRONTEND,
      realm: "frontend",
      countryCode: "",
      username: userdata.username,
      password: userdata.username
    };
    console.log("user", this.addUserFormModel);
    return new Promise((resolve, reject) => {
      this.usercountForSocialLogin(userdata.username).then(res => {
        console.log(res);
        if (res["count"] > 0) {
          console.log("in if");
          this.login(loginInfo).then(
            (ress: any) => {
              this.getUserDetails(ress.userId).then(dataDetail => {
                resolve(res);
                this.loadingService.hideLoading();
              });
              console.log(ress);
            },
            err => {
              console.log(err);
              this.loadingService.hideLoading();
              reject(err);
            }
          );
        } else {
          console.log("in else");
          this.signup(this.addUserFormModel, Config.ROLE.FRONTEND).then(
            ress => {
              console.log("in signup");
              console.log("signup", ress);
              this.login(loginInfo).then(
                resss => {
                  this.getUserDetails().then(dataDetail => {
                    console.log("in login");
                    resolve(res);
                    this.loadingService.hideLoading();
                    console.log(resss);
                  });
                },
                err => {
                  console.log(err);
                  this.loadingService.hideLoading();
                  reject(err);
                }
              );
            }
          );
        }
      });
    });
  }

  forgetPassword(userInfo) {
    this.loadingService.showLoading();
    let forgetUrl = this.apiUrl + "customers/reset";
    return new Promise((resolve, reject) => {
      this.http
        .post(forgetUrl, userInfo, {
          headers: this.headers,
          withCredentials: true
        })
        .subscribe(
          res => {
            this.loadingService.hideLoading();
            this.toast.presentToast(Config.MESSAGE.USER.FORGET_PASSWORD);
            resolve(res);
          },
          err => {
            this.loadingService.hideLoading();
            this.toast.presentToast(Config.LOGIN_ERROR);
            reject(err);
          }
        );
    });
  }

  resetPassword(userInfo) {
    this.loadingService.showLoading();

    let resetUrl =
      this.apiUrl +
      "customers/reset-password?access_token=" +
      JSON.parse(localStorage.getItem("loginUser")).id;

    return new Promise((resolve, reject) => {
      this.http
        .post(resetUrl, userInfo, {
          headers: this.headers,
          withCredentials: true
        })
        .subscribe(
          res => {
            this.loadingService.hideLoading();
            this.toast.presentToast(Config.MESSAGE.USER.RESET_PASSWORD);
            resolve(res);
          },
          err => {
            this.loadingService.hideLoading();
            this.toast.presentToast(Config.LOGIN_ERROR);
            reject(err);
          }
        );
    });
  }

  resetPasswordforgot(userInfo) {
    this.loadingService.showLoading();

    let resetUrl =
      this.apiUrl + "customers/reset-password?access_token=" + userInfo.accessToken;

    return new Promise((resolve, reject) => {
      this.http
        .post(resetUrl, userInfo, {
          headers: this.headers,
          withCredentials: true
        })
        .subscribe(
          res => {
            this.loadingService.hideLoading();
            this.toast.presentToast(Config.MESSAGE.USER.RESET_PASSWORD);
            resolve(res);
          },
          err => {
            this.loadingService.hideLoading();
            this.toast.presentToast(Config.LOGIN_ERROR);
            reject(err);
          }
        );
    });
  }

  logout() {
    this.getLoginUserData();

    this.loadingService.showLoading();

    let logoutUrl =
      this.apiUrl + "/customers/logout?access_token=" + this.accessToken;

    return new Promise((resolve, reject) => {
      this.editUser({ fcmToken: "" }, this.userId).then(res => {
        console.log(res);
        this.http
          .post(logoutUrl, null, {
            headers: this.headers,
            withCredentials: true
          })
          .subscribe(
            ress => {
              this.loadingService.hideLoading();
              localStorage.removeItem("loginUser");
              localStorage.removeItem("userProfile");
              this.toast.presentToast(Config.LOGOUT_SUCCESS);
              resolve(ress);
            },
            err => {
              this.loadingService.hideLoading();
              localStorage.removeItem("loginUser");
              localStorage.removeItem("userProfile");
              this.toast.presentToast(Config.LOGOUT_SUCCESS);
              resolve(err);
            }
          );
      });
    });
  }

  getUserDetails(userIdDetail = null) {
    this.getLoginUserData();

    this.loadingService.showLoading();
    if (userIdDetail !== null) {
      this.userId = userIdDetail;
    }
    let filterJSON = {
      include: {
        relation: "roleMappings"
      }
    };

    let filter = encodeURI(JSON.stringify(filterJSON));
    let profileURL =
      this.apiUrl +
      "customers/" +
      this.userId +
      "?access_token=" +
      this.accessToken +
      "&filter=" +
      filter;

    return new Promise((resolve, reject) => {
      this.http.get(profileURL).subscribe(
        res => {
          localStorage.setItem("userProfile", JSON.stringify(res));
          this.loadingService.hideLoading();
          resolve(res);
        },
        err => {
          this.loadingService.hideLoading();
          this.toast.presentToast(Config.GENERIC_ERROR);
          reject(err);
        }
      );
    });
  }

  getAllUsers(mobileNo) {
    this.getLoginUserData();

    this.loadingService.showLoading();

    let filterJSON = {
      where: {
        mobileNo: mobileNo
      },
    };

    let filter = encodeURI(JSON.stringify(filterJSON));

    let allUsersURL =
      this.apiUrl +
      "customers/" +
      "?access_token=" +
      this.accessToken +
      "&filter=" +
      filter;

    return new Promise((resolve, reject) => {
      this.http.get(allUsersURL).subscribe(
        (res: any) => {
            this.loadingService.hideLoading();
            resolve(res);
        },
        err => {
          this.loadingService.hideLoading();
          this.toast.presentToast(Config.GENERIC_ERROR);
          reject(err);
        }
      );
    });
  }

  signup(userInfo, roleId) {
    this.getLoginUserData();
    this.loadingService.showLoading();

    let signupUrl =
      this.apiUrl +
      "customers" +
      "?access_token=" +
      this.accessToken +
      "&roleId=" +
      roleId;

    return new Promise((resolve, reject) => {
      this.http
        .post(signupUrl, userInfo, {
          headers: this.headers,
          withCredentials: true
        })
        .subscribe(
          res => {
            this.loadingService.hideLoading();
            // this.dialog.openContentDialog(Config.MESSAGE.USER.ADD_TITLE,Config.MESSAGE.USER.ADD);
            // this.toast.presentToast(Config.MESSAGE.USER.ADD);
            resolve(res);
          },
          err => {
            this.loadingService.hideLoading();
            this.toast.presentToast(Config.MESSAGE.USER.SIGNUP_ERR);
            reject(err);
          }
        );
    });
  }

  editUser(userInfo, userId, successMsg = Config.MESSAGE.USER.EDIT) {
    this.getLoginUserData();

    this.loadingService.showLoading();

    let editUserUrl =
      this.apiUrl + "customers/" + userId + "?access_token=" + this.accessToken;

    return new Promise((resolve, reject) => {
      this.http
        .patch(editUserUrl, userInfo, {
          headers: this.headers,
          withCredentials: true
        })
        .subscribe(
          res => {
            this.loadingService.hideLoading();
            this.toast.presentToast(successMsg);
            localStorage.setItem("userProfile", JSON.stringify(res));
            resolve(res);
          },
          err => {
            this.loadingService.hideLoading();
            this.toast.presentToast(Config.GENERIC_ERROR);
            reject(err);
          }
        );
    });
  }

  deleteUser(userId) {
    this.getLoginUserData();


    let dialogTitle = "Delete User";
    let dialogContent = Config.MESSAGE.USER.DELETE_CONFIRM;
    return new Promise((resolve, reject) => {
      // this.dialog.openDialog(dialogTitle,dialogContent).then((isOkay)=>{
      //       if(isOkay){
      this.loadingService.showLoading();

      let deleteUserUrl =
        this.apiUrl + "customers/" + userId + "?access_token=" + this.accessToken;

      this.http
        .delete(deleteUserUrl, { headers: this.headers, withCredentials: true })
        .subscribe(
          res => {
            this.loadingService.hideLoading();
            this.toast.presentToast(Config.MESSAGE.USER.DELETE);
            resolve(res);
          },
          err => {
            this.loadingService.hideLoading();
            this.toast.presentToast(Config.GENERIC_ERROR);
            reject(err);
          }
        );
      // }
      // });
    });
  }

  usercount(mobile) {
    let filterJSON = {
      mobileNo: mobile
      // "roleId":Config.ROLE.FRONTEND
    };
    let filter = encodeURI(JSON.stringify(filterJSON));
    let profileURL = this.apiUrl + "customers/count" + "?where=" + filter;
    return new Promise((resolve, reject) => {
      this.http.get(profileURL).subscribe(
        res => {
          resolve(res);
        },
        err => {
          this.toast.presentToast(Config.GENERIC_ERROR);
          reject(err);
        }
      );
    });
  }

  usercountForSocialLogin(userName) {
    let filterJSON = {
      username: userName
    };
    let filter = encodeURIComponent(JSON.stringify(filterJSON));
    let profileURL = this.apiUrl + "customers/count" + "?where=" + filter;
    return new Promise((resolve, reject) => {
      this.http.get(profileURL).subscribe(
        res => {
          resolve(res);
        },
        err => {
          this.toast.presentToast(Config.GENERIC_ERROR);
          reject(err);
        }
      );
    });
  }

  navigationdata: any;
}
