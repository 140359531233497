import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  loading = false;
  page:any = "home";
  constructor() { }
  hideLoading(){
    this.loading = false;
  }
  showLoading(){
    this.loading = true;
  }

}
