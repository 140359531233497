import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import {  MatCheckboxModule } from '@angular/material/checkbox';

import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

import { AngularFireModule } from 'angularfire2';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';

import { AngularFireStorageModule } from '@angular/fire/storage';
// import { FCM } from '@ionic-native/fcm/ngx';
import { FCM } from 'cordova-plugin-fcm-with-dependecy-updated/ionic/ngx';
import {ScrolleffectDirective} from './directives/scrolleffect.directive'

// import { NgMatSearchBarModule } from 'ng-mat-search-bar';
import { Geolocation } from '@ionic-native/geolocation/ngx';
/*import { ScrolleffectDirective } from './scrolleffect.directive';*/
// import { ShrinkingSegmentHeaderComponent } from './components/shrinking-segment-header/shrinking-segment-header.component';
// import { ShrinkingSegmentHeaderComponent } from '../app/components/shrinking-segment-header.component/shrinking-segment-header.component.component'
// import { NativePageTransitions } from '@ionic-native/native-page-transitions/ngx';

import { ToastComponent } from './shared/toast/toast.component';
import { AlertComponent } from './shared/alert/alert.component';

import { Config } from './core/config';

// import {
//   SocialLoginModule,
//   AuthServiceConfig,
//   GoogleLoginProvider,
//   FacebookLoginProvider,
//   LoginOpt,
//   AuthService
// } from "angularx-social-login";
import { OpenNativeSettings } from '@ionic-native/open-native-settings/ngx';
import { LocationAccuracy } from '@ionic-native/location-accuracy/ngx';
import { AgmCoreModule } from '@agm/core';
import { MatRadioModule } from '@angular/material/radio';
import { FormsModule } from '@angular/forms';
import { SetLocationComponent } from './shared/set-location/set-location.component';
import { SmsRetriever } from '@ionic-native/sms-retriever/ngx';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { Calendar } from '@ionic-native/calendar/ngx';

import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { CommonModule } from '@angular/common';
import { IonicServerModule } from '@ionic/angular-server';
// export function getAuthServiceConfigs() {

//   // const fbLoginOptions: LoginOpt = {
//   //   scope: 'pages_messaging,pages_messaging_subscriptions,email,pages_show_list,manage_pages',
//   //   return_scopes: true,
//   //   enable_profile_selector: true
//   // }; // https://developers.facebook.com/docs/reference/javascript/FB.login/v2.11
   
//   // const googleLoginOptions: LoginOpt = {
//   //   scope: 'profile email'
//   // }; // https://developers.google.com/api-client-library/javascript/reference/referencedocs#gapiauth2clientconfig
 
  
//   // let config = new AuthServiceConfig(
//   //     [
//   //       {
//   //         id: FacebookLoginProvider.PROVIDER_ID,
//   //         provider: new FacebookLoginProvider(Config.FaceBookProviderKey)
//   //       },
//   //       {
//   //         id: GoogleLoginProvider.PROVIDER_ID,
//   //         provider: new GoogleLoginProvider(Config.GoogleProviderKey,googleLoginOptions)
//   //       }
//   //     ]
//   // );
//   return config;
// }

const firebaseConfig = environment.firebase;
export function playerFactory() {
  return player;
}
@NgModule({
  declarations: [
    AppComponent, 
    ScrolleffectDirective,
    ToastComponent,
    AlertComponent,
    SetLocationComponent
    // ShrinkingSegmentHeaderComponent
  ],
  entryComponents: [SetLocationComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    HttpModule,
    // IonicServerModule,
    CommonModule,
    FormsModule,
    IonicModule,
    HttpClientModule,
    // NgMatSearchBarModule,
     IonicModule.forRoot(),
      AppRoutingModule,
      LottieModule.forRoot({ player: playerFactory }),
       AngularFireModule.initializeApp(firebaseConfig), 
       AngularFireStorageModule,
       MatButtonModule,MatRadioModule,
       AgmCoreModule.forRoot({
        apiKey: 'AIzaSyB0pYUlir5lQ_vxEGAFlFKsrrjWm50qMzY', libraries: ["places"]
      }) ,
       AngularFireAuthModule,
  BrowserAnimationsModule, MatButtonModule, MatCheckboxModule,
  //  ServiceWorkerModule.register('ngsw-worker.js', 
  //  { enabled: environment.production }
  //  )
  ],
  providers: [
    StatusBar,
    FCM,
    LocationAccuracy,
    CallNumber,
    Calendar,
    OpenNativeSettings,
    SmsRetriever,
    ToastComponent,
    AlertComponent,
    SplashScreen,Geolocation,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent],
  schemas:      [ CUSTOM_ELEMENTS_SCHEMA ],

})
export class AppModule {}
// AIzaSyAAUdYL0b87elsQ2ewuF2B6qDGH4yKTMaI // Website
// AIzaSyB0pYUlir5lQ_vxEGAFlFKsrrjWm50qMzY // Mobile