import { Component, OnInit } from '@angular/core';
import { AlertController } from '@ionic/angular';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit {

  constructor(public alertController: AlertController) {}

   public async presentAlert(alertTitle:string = 'Alert',alertMessage:string) {
    const alert = await this.alertController.create({
      header: alertTitle,
      animated:true,
      backdropDismiss:false,
      subHeader: '',
      message: alertMessage,
      buttons: ['OK']
    });

    await alert.present();
  }

  public async presentAlertforimage(alertMessage:string) {
    const alert = await this.alertController.create({
      animated:true,
      backdropDismiss:false,
      message: alertMessage,
      buttons: ['OK']
    });

    await alert.present();
  }


  public async presentAlertMultipleButtons() {
    const alert = await this.alertController.create({
      header: 'Alert',
      animated:true,
      backdropDismiss:false,
      subHeader: 'Subtitle',
      message: 'This is an alert message.',
      buttons: ['Cancel', 'Open Modal', 'Delete']
    });

    await alert.present();
  }

  async presentAlertConfirm(alertMessage:string,cancelText='Cancel',okayText='Ok') {
    let choice;
    const alert = await this.alertController.create({
      animated:true,
      backdropDismiss:false,
      header: 'Confirm!',
      message: alertMessage,
      buttons: [
        {
          text: cancelText,
          role: 'cancel',
          
          cssClass: 'secondary',
          handler: (blah) => {
            alert.dismiss(true);
                    return false;
          }
        }, {
          text: okayText,
          handler: () => {
            alert.dismiss(true);
                    return true;
          }
        }
      ],
      

    });

    await alert.present();
    await alert.onDidDismiss().then((data) => {
        choice= data
    })
    return choice;
  }



  ngOnInit() {}

}
