import { Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import { NavController, Platform, ModalController } from '@ionic/angular';
import { UserAddressService } from 'src/app/services/user-address/user-address.service';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { ActivatedRoute, Params } from '@angular/router';
import { LocationAccuracy } from '@ionic-native/location-accuracy/ngx';
import { OpenNativeSettings } from '@ionic-native/open-native-settings/ngx';
import { AlertComponent } from 'src/app/shared/alert/alert.component';
import { AreaService } from 'src/app/services/Area/area.service';
import { ToastComponent } from 'src/app/shared/toast/toast.component';
import { google } from 'google-maps';

@Component({
  selector: 'app-set-location',
  templateUrl: './set-location.component.html',
  styleUrls: ['./set-location.component.scss'],
})
export class SetLocationComponent implements OnInit {

	latitude: number;
	longitude: number;
	zoom: number;
	address: string;
	private geoCoder;
	areaObj:any;
	@ViewChild('search')
	public searchElementRef: ElementRef;
	autocompleteItems:any = [];
	value:any;
	userdata:any;
	editflag:boolean = false;
	useraddress:any;
  showMapsFlag:boolean = false;
  allAddress:any;
  place: any;
  selectedAddress: any;
  constructor(
		private navCtrl:NavController,
    private mapsAPILoader: MapsAPILoader,
    private locationAccuracy: LocationAccuracy,
    private openNativeSettings: OpenNativeSettings,
	private alert:AlertComponent,
	private modal:ModalController,
	private toast:ToastComponent,
    private ngZone: NgZone,
    private platForm:Platform,
    private areaService:AreaService,
    private addressService:UserAddressService,
	) { 
		this.userdata = JSON.parse(localStorage.getItem('userProfile'));
  }
  
//   locationaccuracy(){
//     if(this.platForm.is("cordova")){
//     this.locationAccuracy.canRequest().then((canRequest: boolean) => {
//       console.log("can request",canRequest);
//       if(canRequest) {
//         // the accuracy option will be ignored by iOS
//         this.locationAccuracy.request(this.locationAccuracy.REQUEST_PRIORITY_HIGH_ACCURACY).then(
//           () => {
//             console.log('Request successful');
//               this.googlemaps();
//           },
//           error => {
//             this.alert.presentAlertConfirm("Some of the features of the app require your location to function optimally.","cancel","settings").then((res)=>{
//               if(res['role'] == 'cancel' ){
//                 console.log("cancel");
//               }else{
//                 this.openNativeSettings.open("locations").then((res)=>{
//                   console.log("res",res);
                  
//                   setTimeout(() => {
//                       this.googlemaps();
//                     }, 7000);
      
//                 }).catch((err)=>{
//                   console.log("err",err);
//                 })
//               }
//               });
//             console.log('Error requesting location permissions', error);
//         }
//         );
//       }else{
//         this.googlemaps();
  
//       }
//     });
//   }else{
//     this.googlemaps();
//   }
//   }


	ionViewDidEnter(){
		this.userdata = JSON.parse(localStorage.getItem('userProfile'));

    // this.addressService.getAllUserAddresss(this.userdata.id).then((res)=>{
	// 	console.log(res);
	// 	this.allAddress = res;
	//   })
	this.areaService.getEveryAreas().then((res)=>{
		console.log(res);
		this.allAddress = res;
		if(!localStorage.getItem('locationId')){
			console.log("adf")
			let valu = 0;
			this.allAddress.map((value,index)=>{
				if(this.arePointsNear({lat:value.position.lat,lng:value.position.long},{lat:this.latitude,lng:this.longitude},50)){
					valu = 1;
					this.selectedAddress = value;
					this.changeLocation()
				}	
				if((index == (this.allAddress.length  -1)) && (valu == 0)){
					this.selectedAddress = this.allAddress[this.allAddress.length - 1];
					this.changeLocation()
				}
			})
		}
		console.log(this.address);
		
		this.allAddress.map((element)=>{
			element.breifAddress=element.areaObj.formatted_address.split(',');
			// console.log(element.breifAddress)
			// console.log(element.breifAddress.length)
			

			element.breifAddress=element.breifAddress[element.breifAddress.length-3] + 
			 ','+element.breifAddress[element.breifAddress.length-2] 
			 + ','+element.breifAddress[element.breifAddress.length-1]
			// console.log(element.breifAddress)

		})
	  })
    // setTimeout(() => {
    //   this.locationaccuracy();
    // }, 100);
	}

	goback(){
		this.modal.dismiss();
	  }
	
	  ngOnInit() {
		this.setCurrentLocation();
	  }
  
	//   googlemaps(){
	// 	this.setCurrentLocation();

	// 	this.mapsAPILoader.load().then(() => {
	// 	  this.geoCoder = new google.maps.Geocoder;
	// 	  let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
	// 		types: ['(cities)'],
    //         componentRestrictions: {country: 'in'}
	// 	  });
		  
	// 	  autocomplete.addListener("place_changed", () => {
	// 		this.ngZone.run(() => {
	// 		  //get the place result
	// 		  let place = autocomplete.getPlace();
			  
	// 		  //verify result
	// 		  if (place.geometry === undefined || place.geometry === null) {
	// 			return;
	// 		  }
	// 		  this.place = place.name;
	// 		  console.log(place);
	// 		  console.log("this place",this.place);
	// 		  //set latitude, longitude and zoom
	// 		  this.latitude = place.geometry.location.lat();
	// 		  this.longitude = place.geometry.location.lng();
	// 		  this.zoom = 12;
	// 		  this.getAddress(this.latitude, this.longitude);
	// 		});
	// 	  });
	// 	});
	//   }
	
	
	
	
	  private setCurrentLocation() {
		
		if ('geolocation' in navigator) {
		  navigator.geolocation.getCurrentPosition((position) => {
			if(this.useraddress){
			  console.log(this.useraddress.locationObj.lat,this.useraddress.locationObj.long);
			  this.latitude = parseFloat(this.useraddress.locationObj.lat);
			  this.longitude = parseFloat(this.useraddress.locationObj.long);
			  this.zoom = 8;
			  this.showMapsFlag = true;
			//   this.getAddress(this.latitude, this.longitude);
			}else{
			this.latitude = position.coords.latitude;
			this.longitude = position.coords.longitude;
			console.log(this.latitude,this.longitude);
			this.zoom = 8;
			this.showMapsFlag = true;
			// this.getAddress(this.latitude, this.longitude);
		  }
		  });
		}
	  }
	
	
	//   markerDragEnd($event: MouseEvent) {
	// 	console.log($event);
	// 	this.latitude = $event.coords.lat;
	// 	this.longitude = $event.coords.lng;
	// 	this.getAddress(this.latitude, this.longitude);
	//   }
	
	//   getAddress(latitude, longitude) {
	// 	this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
	// 	  this.areaObj = results[0];
	// 	  if (status === 'OK') {
	// 		if (results[0]) {
	// 			for (var i = 0; i < results.length; i++) {
	// 				if(results[i].types[0] == "administrative_area_level_2")
	// 				{
	// 					console.log(results)
	// 					this.address = results[i]['formatted_address'];
	// 					console.log(this.address);
						
	// 					// console.log(results[i]['formatted_address']);
	// 				break;
	// 				}
	// 			 }
	// 		} else {
	// 		  window.alert('No results found');
	// 		}
	// 	  } else {
	// 		window.alert('Geocoder failed due to: ' + status);
	// 	  }
	
	// 	});
	//   }
	
	  saveLocation(){
		if(this.address){
			this.allAddress.map((res)=>{
				if(res.address.trim() == this.address.trim()){
					this.selectedAddress = res;
				}
			})
		}
		
		setTimeout(() => {
			if(this.selectedAddress){
				this.changeLocation();
			}else{
				this.toast.presentToastError("Please choose service area to continue");
			}
		}, 200);
	
	  
        // if(this.place){
        //   this.areaService.getAllAreas(this.place).then((res:any)=>{
		// 	console.log(res);
		// 	if(res.length > 0){
		// 		localStorage.setItem('locationId',res[0].id);
		// 		this.navCtrl.navigateRoot('/home');
		// 	}else{
		// 		localStorage.removeItem('locationId');
		// 	}
        //   })
        // }
       
    //   console.log(this.address,this.latitude,this.longitude); 
		// let add = this.address.split(',');
		// let state = add[add.length -2].split(' ');
		// console.log(add);
		// console.log(state);
		// let locality ='';
		// for(let i = 2;i <add.length-3;i++){
		//   locality = locality + add[i];
    // }
    // console.log(add,state,locality)
    
	
		
    }
    


    changeLocation(){
	  localStorage.setItem("location",this.selectedAddress.city);
	  localStorage.setItem('locationId',this.selectedAddress.id);
	  this.modal.dismiss();
    }
  
	arePointsNear(checkPoint, centerPoint, km) {
		var ky = 40000 / 360;
		var kx = Math.cos(Math.PI * centerPoint.lat / 180.0) * ky;
		var dx = Math.abs(centerPoint.lng - checkPoint.lng) * kx;
		var dy = Math.abs(centerPoint.lat - checkPoint.lat) * ky;
		return Math.sqrt(dx * dx + dy * dy) <= km;
	  }
}
